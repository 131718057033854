import { easings } from "./easings";

let id = 0;

export const scrollTo = (
  dest: number,
  duration = 250,
  easing: keyof typeof easings = "easeInOutQuad"
) => {
  const startY = window.scrollY;
  const start = window.performance.now();
  const scrollId = ++id;
  const down = dest > startY;

  const dh = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,

    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  );
  const wh = window.innerHeight;
  const scrollAmount = Math.floor(dh - dest < wh ? dh - wh : dest);

  const done = (y: number) => {
    const roundedY = Math.floor(y);
    const fixedY = down ? roundedY + 1 : roundedY - 1;
    return down ? fixedY >= scrollAmount : fixedY <= scrollAmount;
  };

  const scroll = () => {
    const now = window.performance.now();
    const time = Math.min(1, (now - start) / duration);
    const timeFunction = easings[easing](time);

    window.scroll(
      0,
      Math.ceil(timeFunction * (scrollAmount - startY) + startY)
    );

    if (done(window.scrollY) || id !== scrollId) return;

    window.requestAnimationFrame(scroll);
  };

  scroll();
};
