type Items = ReturnType<typeof getParallaxItems>;
export type Item = Items[number];
export type Effect = (
  data: Item & { percentage: number; elY: number; winY: number }
) => void;
type Effects = {
  [id: string]: Effect;
};
type Options = {
  effects?: Effects;
  reset?: boolean;
};

let winHeight: number;
let items: Items;
let itemId = 1;

const getParallaxItems = (y: number) =>
  Array.from(
    document.querySelectorAll<HTMLElement>(`[data-parallax-speed]`),
    (el) => {
      const speed =
        Math.round((1 - +el.getAttribute("data-parallax-speed")!) * 100) / 100;
      const isBackground = el.hasAttribute("data-parallax-background");
      const id = el.getAttribute("data-parallax-id") || itemId;
      const {
        height: parentHeight,
        top,
      } = el.parentElement!.getBoundingClientRect();
      const parentTop = y + top;

      itemId++;

      return { el, parentHeight, parentTop, speed, isBackground, id };
    }
  );

const itemProgress = (height: number, top: number, y: number) => {
  const bottom = top + height;
  const winBottom = y + winHeight;
  const inView = !(top > winBottom || bottom < y);
  // calculate progress of element in scroll view, 0-1
  const t = inView ? 1 - -(y - bottom) / (height + winHeight) : 0;

  return t;
};

interface SetStyle {
  (el: HTMLElement, style: "pos" | "height", value: string | number): void;
  (el: HTMLElement): void;
}

const setStyle: SetStyle = (
  el: HTMLElement,
  style?: "pos" | "height",
  value?: string | number
) =>
  style
    ? (el.style[style === "pos" ? "transform" : style] =
        style === "pos"
          ? `translate3d(0, ${value}px, 0)`
          : `${Math.ceil(Number(value))}px`)
    : ((el.style.transform = ""), (el.style.height = ""));

const processItem = (
  item: Item,
  y: number,
  { effect, isInit }: { effect?: Effect; isInit?: boolean } = {}
) => {
  const { el, parentTop, parentHeight, speed, isBackground } = item;
  const piv = itemProgress(parentHeight, parentTop, y);

  if (!piv && !isInit) return;

  const inlineTarget = parentTop - (winHeight - parentHeight) / 2;
  let elY = Math.ceil((y - (isBackground ? parentTop : inlineTarget)) * speed);

  setStyle(el, "pos", elY);

  if (isBackground && isInit) {
    const diff = winHeight - parentHeight;
    const height = parentHeight + diff * speed;

    setStyle(el, "height", height);
  }

  effect?.({ ...item, percentage: piv, elY, winY: y });
};

let ticking = false;
let reset: ((hard: boolean) => void) | undefined;

export const setup = ({
  effects = {},
  reset: onlyReset = false,
}: Options = {}) => {
  if (reset) reset(onlyReset);

  if (onlyReset) return;

  const onScroll = () => {
    const y = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        items.forEach((item) =>
          processItem(item, y, {
            effect: effects[item.id],
          })
        );

        ticking = false;
      });

      ticking = true;
    }
  };

  reset = (hard) => {
    window.removeEventListener("scroll", onScroll);

    if (hard) items.forEach(({ el }) => setStyle(el));
  };

  window.addEventListener("scroll", onScroll);

  const y = window.scrollY;
  winHeight = window.innerHeight;
  items = getParallaxItems(y);

  items.forEach((el) => {
    processItem(el, y, { isInit: true });
  });

  return reset;
};
