let initialized = false;

const getAddress = () =>
  `info${String.fromCharCode(Math.pow(2, 6))}devisesthlm.se`;

export const inject = () => {
  if (initialized) return;

  const container = document.getElementById("email");
  const link = document.createElement("a");
  const address = getAddress();

  link.setAttribute(
    "href",
    `${["mail", "to"].join("")}${String.fromCharCode(7.25 * 8)}${address}`
  );

  link.innerText = address;

  container?.appendChild(link);

  initialized = true;
};
