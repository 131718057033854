import { large } from "./responsive";
import { setup as navSetup, getElementData, getMiddlePoint } from "./nav";
import { scrollTo } from "./scroll";
import { setup as parallaxSetup, Effect } from "./parallax";
import { setup as carouselSetup, Options as CarouselOptions } from "./carousel";
import { inject } from "./email";
import { register } from "./mail";
import { setup as cookieConsentSetup } from "./consent";

let resizeTimeout: number;
let carouselDiff:
  | { currentY: number; targetY: number; height: number }
  | undefined;

const carouselStep: CarouselOptions["onStateChange"] = ({
  parent,
  index,
  direction,
}) => {
  if (parent.className.includes("screen-")) {
    parent.className = parent.className
      .split(" ")
      .map((c) => (c.includes("screen-") ? `screen-${index}` : c))
      .join(" ");
  } else parent.classList.add(`screen-${index}`);

  const finalHeight = parseInt(parent.style.height);
  const { top, height } = getElementData(parent);
  const targetY = getMiddlePoint(top, finalHeight);

  carouselDiff = {
    height: finalHeight - height,
    currentY: window.scrollY,
    targetY,
  };

  if (direction) {
    scrollTo(targetY, 600, "easeInOutQuad");

    parent.addEventListener("transitionend", function end(e) {
      if (e.target === parent && e.propertyName === "height") {
        setup();
        carouselDiff = undefined;

        parent.removeEventListener("transitionend", end);
      }
    });
  }
};

const carouselMod: Effect = ({ el, elY, winY, speed }) => {
  if (carouselDiff !== undefined) {
    const { currentY, targetY, height } = carouselDiff;
    const yDiff = winY - currentY; // scroll progress in px
    const perc = yDiff / (targetY - currentY); // percentage of scroll distance
    const heightAdjust = Math.round(height * perc * speed); // current height of carousel * speed

    el.style.transform = `translate3d(0px, ${elY - heightAdjust}px, 0px)`;
  }
};

const fade: Effect = ({ el, percentage: p }) => {
  el.style.opacity = `${1.2 - Math.pow(p + 0.2, 3)}`;
  el.style.filter = `blur(${Math.pow(p, 6) * 20}px)`;
  el.style.letterSpacing = `${Math.pow(p, 3) * 20}px`;
};

const parallaxOptions = {
  effects: { fade, ["contact-bg"]: carouselMod, ["contact-text"]: carouselMod },
};

let lastWinWidth = 0;

const setup = (force?: boolean) => {
  if (lastWinWidth !== window.outerWidth || force)
    carouselSetup({ onStateChange: carouselStep, initialIndex: 0 });

  parallaxSetup(large.matches ? parallaxOptions : { reset: true });
  navSetup();

  lastWinWidth = window.outerWidth;

  inject();
  cookieConsentSetup();
};

window.addEventListener("resize", () => {
  clearTimeout(resizeTimeout);

  resizeTimeout = setTimeout(setup, 250);
});

window.addEventListener("load", setup.bind(null, true));

setup();

register("mailForm", "formStatus");
