type Direction = "forward" | "back";
type CallbackArg = {
  parent: HTMLElement;
  el: HTMLElement;
  index: number;
  direction?: Direction;
};
type Callback = (arg: CallbackArg) => void;

export type Options = {
  initialIndex?: number;
  onStateChange?: Callback;
};

let carouselId = 0;

const getIndex = (s: number, len: number) => {
  const part = (s / len) % 1; // -0.25, -0, 0.25, 0.5 ...
  const posPart = Math.abs(part < 0 ? 1 + part : part); // 0.75, 0, 0.25, 0.5 ...

  return len * posPart; // 4 * 0,25 = index in screens array
};

const setHeight = (parent: HTMLElement, next: Element | null) => {
  if (!next) return;

  const { height } = next.getBoundingClientRect();

  parent.style.height = `${height}px`;
};

const makeTransition = (
  parent: HTMLElement,
  screens: HTMLElement[],
  { initialIndex = 0, onStateChange }: Options
) => {
  const cid = carouselId++;
  let iterator = initialIndex;
  let changeData: CallbackArg;

  const transition = (dir?: Direction) => (next: number) => {
    const len = screens.length;
    const nextIndex = getIndex(next, len);
    const prevId = `c${cid}-${iterator}`;
    const id = `c${cid}-${++iterator}`;

    const prevEl = document.getElementById(prevId);
    const nextEl = screens[nextIndex].cloneNode(true) as HTMLElement;

    nextEl.setAttribute("id", id);

    if (dir) {
      nextEl.classList.add(`c-in-${dir}-init`);
      prevEl?.classList.add(`c-out-${dir}`);
    }
    parent.appendChild(nextEl);

    setHeight(parent, nextEl); // also forces reflow to get below class change to have an effect

    if (dir) nextEl.classList.add(`c-in-${dir}`);

    setTimeout(() => prevEl?.remove(), 2000);

    changeData = { parent, el: nextEl, index: nextIndex, direction: dir };

    onStateChange?.(changeData);
  };

  transition()(initialIndex);

  return {
    forward: transition("forward"),
    back: transition("back"),
    resetHeight: () => setHeight(parent, parent.lastElementChild),
    getChangeData: () => changeData,
  };
};

const start = (parent: HTMLElement, options: Options) => {
  let state = options.initialIndex || 0;
  const bBtn = document.querySelector<HTMLElement>("[data-carousel-back]");
  const fwBtn = document.querySelector<HTMLElement>("[data-carousel-forward]");
  const screens = ([...parent.children] as HTMLElement[])
    .filter((el) => el.hasAttribute("data-carousel-screen"))
    .map((el) => parent.removeChild(el));
  const { forward, back, resetHeight, getChangeData } = makeTransition(
    parent,
    screens,
    options
  );

  if (bBtn) bBtn.addEventListener("click", () => back(--state));
  if (fwBtn) fwBtn.addEventListener("click", () => forward(++state));

  return () => {
    resetHeight();
    options.onStateChange?.(getChangeData());
  };
};

let initialized = false;
let resetFns: (() => void)[] = [];

export const setup = (options: Options = {}) => {
  if (initialized) {
    resetFns.forEach((f) => f());
    return;
  }

  const carousels = document.querySelectorAll<HTMLElement>("[data-carousel]");

  if (!carousels.length) return;

  carousels.forEach((parent) => {
    resetFns.push(start(parent, options));
  });

  initialized = true;
};
