import { scrollTo } from "./scroll";

type Sections = ReturnType<typeof getElementData>[];

let activeItem = "";
let sections: Sections;
let links: HTMLAnchorElement[];

export const getMiddlePoint = (top: number, height: number) =>
  top - window.innerHeight / 2 + height / 2;

export const getElementData = (el: Element) => {
  const { top, height } = el.getBoundingClientRect();

  return { id: el.id, top: top + window.scrollY, height };
};

const scrollToSection = (destination?: number) =>
  scrollTo(destination || 0, 500, "easeInOutQuart");

const createNav = () =>
  sections.map((d) => {
    const link = document.createElement("a");
    const span = document.createElement("span");

    link.setAttribute("href", `#${d.id}`);
    link.setAttribute("class", "nav-link");
    link.dataset.id = d.id;
    span.innerText = d.id;

    link.appendChild(span);

    return link;
  });

const createFooterLinks = () => {
  const container = document.getElementById("sections")!;

  sections.forEach(({ id }) => {
    const link = document.createElement("a");

    link.setAttribute("href", `#${id}`);
    link.innerText = id;

    link.onclick = (e: MouseEvent) => {
      e.preventDefault();

      const { top, height } = getElementData(document.getElementById(id)!);

      scrollToSection(getMiddlePoint(top, height));
    };

    container.appendChild(link);
  });
};

const setActiveNavItem = (links: HTMLAnchorElement[], active: string) => {
  links.forEach((link) => {
    if (link.dataset.id === active) link.classList.add("active");
    else link.classList.remove("active");
  });

  activeItem = active;
};

let ticking = false;
let scrollTimer: number;

const toggleItems = (_?: any, force?: boolean) => {
  clearTimeout(scrollTimer);
  const y = window.scrollY + window.innerHeight / 2;

  const active = sections.reduce((acc, s) => {
    if (s.top < y && s.top + s.height > y) return s.id;
    else return acc;
  }, "");

  if (active !== activeItem || force) setActiveNavItem(links, active);

  ticking = false;

  scrollTimer = setTimeout(
    () => history.pushState(null, "", `#${active}`),
    100
  );
};

const onScroll = () => {
  if (!ticking) {
    window.requestAnimationFrame(toggleItems);

    ticking = true;
  }
};

let reset: (() => void) | undefined;

export const setup = () => {
  let listeners = new Map<HTMLAnchorElement, (e: MouseEvent) => void>();
  const nav = document.querySelector("nav")!;
  sections = [].slice
    .call(document.querySelectorAll("section[id]"))
    .map(getElementData);

  if (reset) reset();
  else createFooterLinks();

  links = createNav();

  links.forEach((link) => {
    const listener = (e: MouseEvent) => {
      e.preventDefault();
      const id = (e.target as HTMLElement).closest("a")!.dataset.id;
      const { top, height } = sections.find((s) => s.id === id)!;

      scrollToSection(getMiddlePoint(top, height));
    };

    listeners.set(link, listener);

    link.addEventListener("click", listener);

    if (activeItem === link.dataset.id) link.classList.add("active");

    nav.appendChild(link);
  });

  reset = () => {
    nav.innerHTML = "";

    listeners.forEach((listener, el) => {
      el.removeEventListener("click", listener);
    });

    window.removeEventListener("scoll", onScroll);
  };

  window.addEventListener("scroll", onScroll);

  toggleItems(null, true);

  return reset;
};
