const send = (body: FormData) =>
  fetch("./mail.php", {
    method: "post",
    body,
  }).then((res) => {
    if (!res.ok) throw res;

    return res;
  });

const hide = (el: HTMLSpanElement) => {
  const { height } = el.getBoundingClientRect();
  el.classList.remove("show");
  el.style.height = `${height}px`;

  if (height)
    el.addEventListener("transitionend", () => (el.style.height = "0px"), {
      once: true,
    });
};

export const register = (formId: string, statusId: string) => {
  let statusTimer: number | undefined = undefined;

  const form = document.getElementById(formId) as HTMLFormElement | null;
  const status = document.getElementById(statusId) as HTMLSpanElement | null;

  const setStatus = (type?: "success" | "error") => {
    if (!status) return;

    clearTimeout(statusTimer);

    if (type) {
      status.style.removeProperty("height");
      status.classList.remove("error", "success");
      status.innerHTML = status.dataset[type] || "";
      status.classList.add(type, "show");
    } else hide(status);

    if (type === "success") statusTimer = setTimeout(() => hide(status), 3000);
  };

  const disable = () => form?.classList.add("disabled");
  const enable = () => form?.classList.remove("disabled");

  form?.addEventListener("submit", (e) => {
    e.preventDefault();
    disable();
    setStatus();

    send(new FormData(form))
      .then(() => {
        form.reset();

        enable();

        setStatus("success");
      })
      .catch(() => {
        enable();

        setStatus("error");
      });
  });
};
