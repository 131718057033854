const KEY = "CookieConsentLevel"

const onSuccess = () => {
  const script = document.createElement("script")

  script.src = "https://www.googletagmanager.com/gtag/js?id=UA-178485209-1"

  document.body.appendChild(script)
}

export let setup = () => {
  setup = () => void null;

  const status = localStorage.getItem(KEY)

  if (status) return status === "1" && onSuccess();

  const box = document.getElementById("consent")

  box?.classList.remove("hidden")

  box?.addEventListener("click", (e) => {
    if (e.target instanceof HTMLButtonElement) {
      const success = e.target.id.includes("Accept");
      
      localStorage.setItem(KEY, String(success ? 1 : 0))

      if (success) onSuccess()

      box?.classList.add("hidden")
    }
  })
}